import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  DocumentChangeAction,
  Query,
} from "@angular/fire/firestore";
import { Observable, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import * as firebase from "firebase/app";
import { WhereFilterOp } from "@firebase/firestore-types";
import { firebaseConsts } from "../constants";
import { Message } from "../models/message";
import { BalanceStatus, BalanceType } from "../models/enuns/balanceType";
import { subHours } from "date-fns";
import { TariffStatusType } from "../models/tariffStatusType";

export interface QueryParams {
  field: string;
  value: any;
  field1?: string;
  value1?: string;
  orderBy?: string;
  operator?: WhereFilterOp;
  limit?: number;
}

@Injectable({
  providedIn: "root",
})
export class FirebaseDatabaseService {
  constructor(public afs: AngularFirestore) { }

  public getNowTimestamp(): firebase.firestore.Timestamp {
    let now = new Date(Date.now());
    let nowTimestamp = firebase.firestore.Timestamp.fromDate(now);
    return nowTimestamp;
  }

  public async getDoc(collection: string, id: string) {

    let ref = this.afs.collection(collection).doc(id).ref;
    const snapshot = await ref.get();
    if (snapshot.exists) {
      const doc = {
        id: snapshot.id,
        ...snapshot.data()
      }
      return doc;
    } else {
      return null;
    }

  }

  public async getDataById(collection: string, id: string): Promise<any> {
    if (!collection || !id) return;
    return new Promise<any>(async (resolve, reject) => {
      try {
        const docRef = this.afs.collection(collection).doc(id).ref;
        const docData = await docRef.get();
        if (docData.exists) {
          resolve({
            ...docData.data(),
            id: docData.id,
            key: docData.id
          });
        } else resolve(null);
      } catch (err) {
        reject(err);
      }
    });
  }

  public addDoc(collection: string, doc: any) {
    if (!collection || !doc) return;
    let newDoc: any = {
      createdAt: null,
      updatedAt: null,
    };
    newDoc = doc;
    newDoc.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    newDoc.updatedAt = null;

    return new Promise((resolve, reject) => {
      this.afs
        .collection(collection)
        .add(newDoc)
        .then((res: any) => {
          newDoc.id = res.id;
          resolve(newDoc)
        })
        .catch((err) => reject(err));
    });
  }

  public setDoc(collection: string, key: string) {
    let newDoc = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    newDoc.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    return new Promise((resolve, reject) => {
      this.afs
        .collection(collection)
        .doc(key)
        .set(newDoc)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  public removeDoc(collection: string, key: any) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(collection)
        .doc(key)
        .delete()
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  public watchCollectionByField(
    fieldValue,
    fieldName,
    collection
  ): Observable<DocumentChangeAction<any>[]> {
    let ref = this.afs.collection(collection, (ref) =>
      ref.where(fieldName, "==", fieldValue)
    );
    return ref.snapshotChanges();
  }

  public watchBalance(ownerId: string): Observable<DocumentChangeAction<any>[]> {
    let ref = this.afs.collection(firebaseConsts.collections.balance, (ref) =>
      ref.where('owner.id', '==', ownerId)
        .where('status', '==', BalanceStatus.Approved)
        .where('type', '==', BalanceType.Addition)
        .orderBy('approvedDate', 'desc')
        .limit(1)
    );
    return ref.snapshotChanges();
  }

  public getCollection(query: Query) {
    return new Promise((resolve, reject) => {
      query
        .get()
        .then((result) => {
          let arr = [];
          result.forEach((doc) => {
            var obj = JSON.parse(JSON.stringify(doc.data()));
            obj.key = doc.id;
            arr.push(obj);
          });

          if (arr.length > 0) {
            resolve(arr);
          } else {
            resolve(arr);
          }
        })
        .catch((err) => reject(err));
    });
  }

  public getCollectionType(query: Query, T: any) {
    return new Promise<typeof T>((resolve, reject) => {
      query
        .get()
        .then((result) => {
          let arr = [];
          result.forEach((doc) => {
            var obj = JSON.parse(JSON.stringify(doc.data()));
            obj.key = doc.id;
            arr.push(obj);
          });

          if (arr.length > 0) {
            resolve(arr);
          } else {
            resolve(arr);
          }
        })
        .catch((err) => reject(err));
    });
  }

  public async readDoc(
    collection: string,
    whereList: QueryParams[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let ref = this.afs.collection(collection).ref;
      let query: Query;

      whereList.forEach((where) => {
        let operator = where.operator ? where.operator : "==";

        if (where.orderBy && where.limit)
          query = ref
            .where(where.field, operator, where.value)
            .orderBy(where.orderBy)
            .limit(where.limit);
        else if (where.orderBy)
          query = ref
            .where(where.field, operator, where.value)
            .orderBy(where.orderBy);
        else if (where.limit)
          query = ref
            .where(where.field, operator, where.value)
            .limit(where.limit);
        else query = ref.where(where.field, operator, where.value);
      });

      query
        .get()
        .then((result) => {
          let arr = [];
          result.forEach((doc) => {
            var obj = JSON.parse(JSON.stringify(doc.data()));
            obj.key = doc.id;
            arr.push(obj);
          });

          if (arr.length > 0) {
            resolve(arr);
          } else {
            resolve(arr);
          }
        })
        .catch((err) => reject(err));
    });
  }

  public updateDoc(collection: string, doc: any, key = null) {
    const useKey = key ? key : doc.key;

    let newDoc = {
      updatedAt: this.getNowTimestamp(),
    };
    newDoc = doc;
    newDoc.updatedAt = this.getNowTimestamp();

    return new Promise((resolve, reject) => {
      this.afs
        .collection(collection)
        .doc(useKey)
        .update(newDoc)
        .then((res) => resolve(newDoc))
        .catch((err) => reject(err));
    });
  }

  execQuery(query: firebase.firestore.Query, lastVisible?) {
    return new Promise((resolve, reject) => {
      query
        .get()
        .then((documentSnapshots) => {
          let result = {
            collection: [],
            lastVisible:
              documentSnapshots.docs.length > 0
                ? documentSnapshots.docs[documentSnapshots.docs.length - 1]
                : lastVisible,
          };
          documentSnapshots.forEach((doc) => {
            var obj = JSON.parse(JSON.stringify(doc.data()));
            obj.key = doc.id;
            result.collection.push(obj);
          });

          if (result.collection.length > 0) {
            resolve(result);
          } else {
            console.log("No such document!");
            resolve(result);
          }
        })
        .catch((err) => reject(err));
    });
  }

  public async getByFilter(
    collection: string,
    params: QueryParams,
    direction: firebase.firestore.OrderByDirection = "asc"
  ): Promise<any> {
    // console.log(params);
    let collectionRef = this.afs
      .collection(collection)
      .ref.where(params.field, "==", params.value)
      // .where(params.field1, ">=", params.value1)
      .orderBy(params.field1)
      .limit(params.limit)
      .startAt(params.value1)
      .endAt(params.value1 + "\uf8ff");

    // let first = collectionRef

    return this.execQuery(collectionRef);
  }

  public async getFirstByPagination(
    collection: string,
    params: QueryParams
  ): Promise<any> {
    let collectionRef = this.afs
      .collection(collection)
      .ref.where(params.field, "==", params.value)
      .orderBy(params.orderBy)
      .limit(params.limit);
    // let first = collectionRef

    return this.execQuery(collectionRef);
  }

  public async getPriorByPagination(
    collection: string,
    params: QueryParams,
    lastVisible: any
  ): Promise<any> {
    let collectionRef = this.afs.collection(collection).ref;
    let prior = collectionRef
      .orderBy(params.orderBy)
      .limit(params.limit)
      .endAt(lastVisible);
    return this.execQuery(prior);
  }

  public async getNextByPagination(
    collection: string,
    params: QueryParams,
    lastVisible: any
  ): Promise<any> {
    console.log(params);
    let collectionRef = this.afs.collection(collection).ref;
    let next = collectionRef
      .where(params.field, "==", params.value)
      .orderBy(params.orderBy)
      .limit(params.limit)
      .startAfter(lastVisible);
    return this.execQuery(next, lastVisible);
  }

  watchSubsidiaryTariffs(
    subsidiary,
    calculationMethod,
    driverCategory
  ): Observable<DocumentChangeAction<any>[]> {
    let ref = this.afs.collection(firebaseConsts.collections.tariffs, (ref) =>
      ref
        .where("subsidiary", "==", subsidiary)
        .where("categoryDriver", "==", driverCategory)
        .where("company", "==", null)
        .where("disabledAt", "==", null)
        .where("calculationMethod", "==", calculationMethod)
    );
    return ref.snapshotChanges();
  }

  watchCompanyTariffs(
    company,
    calculationMethod,
    driverCategory
  ): Observable<DocumentChangeAction<any>[]> {
    let ref = this.afs.collection(firebaseConsts.collections.tariffs, (ref) =>
      ref
        .where("company", "==", company)
        .where("categoryDriver", "==", driverCategory)
        .where("disabledAt", "==", null)
        .where("calculationMethod", "==", calculationMethod)
    );
    return ref.snapshotChanges();
  };

  watchTariffsDynamics(subsidiaryId: string, category: number): Observable<DocumentChangeAction<any>[]> {
    let ref = this.afs.collection(firebaseConsts.collections.tariffsDynamics, (ref) =>
      ref
        .where("subsidiary", "==", subsidiaryId)
        .where("categoryDriver", "==", category)
        .where("activated", "==", TariffStatusType.Active)
        // .where("exceptionCustomers", "not-in", [clientId])
        .orderBy("createdAt", "desc")
        .limit(1)
    );
    return ref.snapshotChanges();
  }

  watchTariffsDynamicsScheduled(subsidiaryId: string, category: number): Observable<DocumentChangeAction<any>[]> {
    const now = new Date();
    let ref = this.afs.collection(firebaseConsts.collections.tariffsDynamics, (ref) =>
      ref
        .where("subsidiary", "==", subsidiaryId)
        .where("categoryDriver", "==", category)
        .where("activated", "==", TariffStatusType.Schedule)
        // .where("exceptionCustomers", "not-in", [clientId])
        .orderBy('activateUntil', 'desc')
        .endAt(now)
        .limit(1)
    );
    return ref.snapshotChanges();
  }

  public getStringToTimestamp(sdate): firebase.firestore.Timestamp {
    let date = new Date(sdate);
    let nowTimestamp = firebase.firestore.Timestamp.fromDate(date);
    return nowTimestamp;
  }

  getIfoodClients() {
    let ref = this.afs.collection(firebaseConsts.collections.clients).ref;
    let query: Query;
    query = ref
      .where("ifood.disabledAt", "==", null)
    return this.getCollection(query);
  }

  deliveryBetweenDate(clientKey, dateIni, dateFin, status) {
    let ref = this.afs.collection(firebaseConsts.collections.calls).ref;
    let query: Query;
    if (status && status != "null") {
      query = ref
        .where("clientKey", "==", clientKey)
        .where("status", "==", status)
        .orderBy("createdAt")
        .startAt(dateIni)
        .endAt(dateFin);
    } else {
      query = ref
        .where("clientKey", "==", clientKey)
        .orderBy("createdAt")
        .startAt(dateIni)
        .endAt(dateFin);
    }

    return this.getCollection(query);
  }

  public deleteDoc(collection: string, doc: any) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(collection)
        .doc(doc.key)
        .delete()
        .then((res) => resolve(doc))
        .catch((err) => reject(err));
    });
  }

  public watchDocByKey(collection: string, key: string) {
    let ref = this.afs.collection(collection).doc(key);
    return ref.valueChanges();
  }

  addToChat(key: string, msg: Message) {
    return new Promise(async (resolve, reject) => {
      try {
        const callRef = this.afs.collection("calls").doc(key).ref;
        this.afs.firestore.app.firestore().runTransaction((transaction) => {
          return transaction.get(callRef).then(async (snapshot) => {
            let notifications = [];
            notifications = await snapshot.get("notifications");
            const message = {
              message: msg.message,
              sender: msg.sender,
              senderPhotoURL: msg.senderPhotoURL,
              senderKey: msg.senderKey,
              read: false,
              createdAt: this.getNowTimestamp(),
            };
            // console.log(createdDate);

            await notifications.push(message);
            await transaction.update(callRef, "notifications", notifications);
            await resolve(true);
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  }
}
