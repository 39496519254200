import { Injectable } from '@angular/core';
import { Delivery, DeliveryClusteringService, OptimizedRoute } from './delivery-clustering.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryClusteringv2Service {

  constructor(private deliveryClusteringService: DeliveryClusteringService) { }

  optimizeDeliveryRoutes(central: { lat: number, lon: number }, deliveries: Delivery[]): OptimizedRoute[] {
    const clusters = this.clusterDeliveries(deliveries);
    return this.optimizeClusters(clusters, central);
  }

  private clusterDeliveries(deliveries: Delivery[]): Delivery[][] {
    const sorted = [...deliveries].sort((a, b) => b.distanceFromOrigin - a.distanceFromOrigin);
    const clusters: Delivery[][] = [];
    const used = new Set<string>();
  
    while (used.size < sorted.length) {
      const currentCluster: Delivery[] = [];
      
      // Encontra a entrega mais distante não alocada
      const farthest = sorted.find(d => !used.has(d.id));
      if (!farthest) break;
  
      currentCluster.push(farthest);
      used.add(farthest.id);
  
      // Encontra até 2 entregas mais próximas para formar o cluster
      let candidates = sorted.filter(d => !used.has(d.id));
      for (let i = 0; i < 2 && candidates.length > 0; i++) {
        const nearest = this.findNearest(farthest, candidates);
        currentCluster.push(nearest);
        used.add(nearest.id);
        candidates = candidates.filter(d => d.id !== nearest.id);
      }
  
      // Garante clusters de 2 ou 3 entregas
      if (currentCluster.length >= 2) {
        clusters.push(currentCluster);
      }
    }
  
    // Trata casos residuais combinando clusters pequenos
    return this.combineSmallClusters(clusters);
  }

  private combineSmallClusters(clusters: Delivery[][]): Delivery[][] {
    const result: Delivery[][] = [];
    
    for (const cluster of clusters) {
      if (cluster.length === 3) {
        result.push(cluster);
        continue;
      }
  
      // Tenta combinar clusters de 2
      const match = result.find(c => c.length === 2 && c.length + cluster.length <= 3);
      if (match) {
        match.push(...cluster);
      } else {
        result.push(cluster);
      }
    }
  
    return result.filter(c => c.length >= 2 && c.length <= 3);
  }

  private findNearest(target: Delivery, candidates: Delivery[]): Delivery {
    let minDistance = Infinity;
    let nearest = candidates[0];
  
    for (const candidate of candidates) {
      const distance = this.deliveryClusteringService.haversineDistance(
        target.lat, target.lon,
        candidate.lat, candidate.lon
      );
      
      if (distance < minDistance) {
        minDistance = distance;
        nearest = candidate;
      }
    }
  
    return nearest;
  }

  private optimizeClusters(clusters: Delivery[][], central: { lat: number, lon: number }): OptimizedRoute[] {
    return clusters.map(cluster => {
      let bestRoute = cluster;
      let minDistance = this.calculateRouteDistance(cluster, central);
  
      // Testa todas as permutações para clusters de 3 entregas
      if (cluster.length === 3) {
        for (const permutation of this.permute(cluster)) {
          const distance = this.calculateRouteDistance(permutation, central);
          if (distance < minDistance) {
            minDistance = distance;
            bestRoute = permutation;
          }
        }
      }
      // Para 2 entregas, testa as duas possibilidades
      else if (cluster.length === 2) {
        const reversed = [cluster[1], cluster[0]];
        const reversedDistance = this.calculateRouteDistance(reversed, central);
        
        if (reversedDistance < minDistance) {
          minDistance = reversedDistance;
          bestRoute = reversed;
        }
      }
  
      return {
        deliveries: bestRoute,
        totalDistance: minDistance,
        totalTime: bestRoute.reduce((sum, d) => sum + d.timeFromOrigin, 0)
      };
    });
  }

  private calculateRouteDistance(route: Delivery[], central: { lat: number, lon: number }): number {
    let distance = this.deliveryClusteringService.haversineDistance(
      central.lat, central.lon,
      route[0].lat, route[0].lon
    );
  
    for (let i = 1; i < route.length; i++) {
      distance += this.deliveryClusteringService.haversineDistance(
        route[i-1].lat, route[i-1].lon,
        route[i].lat, route[i].lon
      );
    }
    return distance;
  }

  private permute<T>(arr: T[]): T[][] {
    const result: T[][] = [];
  
    const permute = (arr: T[], m: T[] = []) => {
      if (arr.length === 0) result.push(m);
      else arr.forEach((_, i) => {
        const curr = arr.slice();
        const next = curr.splice(i, 1);
        permute(curr.slice(), m.concat(next));
      });
    };
  
    permute(arr);
    return result;
  }
}
