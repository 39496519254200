import { Injectable } from '@angular/core';

export interface Delivery {
  id: string;
  address: string;
  lat: number;
  lon: number;
  distanceFromOrigin: number;
  timeFromOrigin: number;
}

export interface OptimizedRoute {
  deliveries: Delivery[];
  totalDistance: number;
  totalTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeliveryClusteringService {

  constructor() { }

  optimizeDeliveryRoutes(centralPoint: { lat: number, lon: number }, deliveries: Delivery[]): OptimizedRoute[] {
    const clusters = this.clusterDeliveries(deliveries);
    return this.optimizeClusters(clusters, centralPoint);
  }

  private clusterDeliveries(deliveries: Delivery[]): Delivery[][] {
    const sorted = [...deliveries].sort((a, b) => b.distanceFromOrigin - a.distanceFromOrigin);
    const clusters: Delivery[][] = [];
    const usedIds = new Set<string>();

    while (usedIds.size < sorted.length) {
      const cluster: Delivery[] = [];

      // Encontra a próxima entrega mais distante não utilizada
      const farthest = sorted.find(d => !usedIds.has(d.id));
      if (!farthest) break;

      cluster.push(farthest);
      usedIds.add(farthest.id);

      // Encontra as 2 entregas mais próximas da atual
      let remaining = sorted.filter(d => !usedIds.has(d.id));
      for (let i = 0; i < 2 && remaining.length > 0; i++) {
        const nearest = this.findNearest(farthest, remaining);
        cluster.push(nearest);
        usedIds.add(nearest.id);
        remaining = remaining.filter(d => d.id !== nearest.id);
      }

      clusters.push(cluster);
    }

    return clusters;
  }

  private findNearest(target: Delivery, candidates: Delivery[]): Delivery {
    let minDistance = Infinity;
    let nearest = candidates[0];

    for (const candidate of candidates) {
      const distance = this.haversineDistance(
        target.lat, target.lon,
        candidate.lat, candidate.lon
      );

      if (distance < minDistance) {
        minDistance = distance;
        nearest = candidate;
      }
    }

    return nearest;
  }

  private optimizeClusters(clusters: Delivery[][], central: { lat: number, lon: number }): OptimizedRoute[] {
    return clusters.map(cluster => {
      let bestRoute: Delivery[] = [];
      let minDistance = Infinity;

      for (const permutation of this.permute(cluster)) {
        const distance = this.calculateRouteDistance(permutation, central);
        if (distance < minDistance) {
          minDistance = distance;
          bestRoute = permutation;
        }
      }

      return {
        deliveries: bestRoute,
        totalDistance: minDistance,
        totalTime: bestRoute.reduce((sum, d) => sum + d.timeFromOrigin, 0)
      };
    });
  }

  private calculateRouteDistance(route: Delivery[], central: { lat: number, lon: number }): number {
    let distance = 0;
    let previous = { lat: central.lat, lon: central.lon };

    for (const delivery of route) {
      distance += this.haversineDistance(
        previous.lat, previous.lon,
        delivery.lat, delivery.lon
      );
      previous = delivery;
    }

    return distance;
  }

  haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371e3;
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  private permute<T>(arr: T[]): T[][] {
    const result: T[][] = [];

    const permute = (arr: T[], m: T[] = []) => {
      if (arr.length === 0) {
        result.push(m);
      } else {
        for (let i = 0; i < arr.length; i++) {
          const curr = arr.slice();
          const next = curr.splice(i, 1);
          permute(curr.slice(), m.concat(next));
        }
      }
    };

    permute(arr);
    return result;
  }

}
